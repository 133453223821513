import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";
import { format, parse, differenceInMinutes } from "date-fns";
import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
    Dialog,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import {
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Line,
    Label,
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    BarChart,
    Bar,
} from "recharts";
// import { GetStaffList } from "~slices/Staff";
import { GetVisitAggregate } from "~slices/Analysis";

const Analysis = () => {
    const dispatch = useDispatch();
    const AnalysisState = useSelector((state) => state.Analysis);
    const visit_aggregate = AnalysisState.visit_aggregate;

    const InformationState = useSelector((state) => state.Information);
    const config = InformationState.config;

    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState((new Date().getMonth() + 1).toString().padStart(2, "0"));

    const [payment_methods, setPaymentMethods] = useState("all");
    const [open_purchase_group_category, setOpenPurchaseGroupCategory] = useState(false);

    // const [staff_drink_check, setStaffDrink] = useState(config.staff_drink);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        const params = { year: year, month: month, attendance_deadline: config.attendance_deadline };
        dispatch(GetVisitAggregate(params));
    }, []);

    const HandleChangeMonth = (e) => {
        if (!e.target.value) return;
        const arr = e.target.value.split("-");
        setYear(arr[0]);
        setMonth(arr[1]);
        const params = { year: arr[0], month: arr[1], attendance_deadline: config.attendance_deadline };
        dispatch(GetVisitAggregate(params));
    };

    const HandleChangePaymentMethods = (e) => {
        if (!e.target.value) return;

        setPaymentMethods(e.target.value);

        const params = {
            year: year,
            month: month,
            attendance_deadline: config.attendance_deadline,
            payment_methods: e.target.value,
        };
        dispatch(GetVisitAggregate(params));
    };

    const RADIAN = Math.PI / 180;
    const customizedLabel = (arg) => {
        const { cx, cy, midAngle, innerRadius, outerRadius, type_label, total_menu_price } = arg;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN) - 50;
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text x={x} y={y} dominantBaseline="central">
                {type_label}
                {total_menu_price}円
            </text>
        );
    };
    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

    //スタッフドリンクの売上合計金額
    const total_staff_drink_price = Number(AnalysisState.staff_drink_sum.total_menu_price);

    //人件費合計
    // const personnel_fee = Number(AnalysisState?.attendance_hourly_sum_total);

    const personnel_fee = Number(AnalysisState?.attendance_salary_sum);

    //仕入額合計
    const purchase_sum = Number(AnalysisState.purchase_group_type_sum);

    //売上合計
    const total_amount = Number(AnalysisState.total_amount);
    //売上合計現金以外
    const total_amount_no_cash = Number(AnalysisState.total_amount_no_cash);

    //課税対象売上
    const taxation_target_sales = Number(AnalysisState.total_amount) - purchase_sum;

    //消費税
    const tax = Math.floor(taxation_target_sales - taxation_target_sales / 1.1);

    const sub_data_list = [
        { label: "来店合計人数", value: AnalysisState.total_people, unit: "人" },
        {
            label: "平均客単価",
            value: Number(Math.round(AnalysisState.total_amount / AnalysisState.total_people)).toLocaleString(),
            unit: "円",
        },
    ];

    const data_list = [
        {
            label: "総売上",
            explanation: "(税込み)",
            value: total_amount.toLocaleString(),
            unit: "円",
        },
        // { label: "売上合計現金以外", value: total_amount_no_cash.toLocaleString(), unit: "円" },
        // { label: "繰り上げ繰り下げ金額合計", value: AnalysisState.total_round_amount.toLocaleString(), unit: "円" },

        {
            label: "10%消費税合計",
            // explanation: `消費税${AnalysisState.total_tax_each_tax_rate["10"]}円`,
            // value: Number(AnalysisState.total_amount_each_tax_rate["10"]).toLocaleString(),
            explanation: `消費税${AnalysisState.tax_10}円`,
            value: Number(AnalysisState.included_10).toLocaleString(),
            unit: "円",
        },

        {
            label: "8%消費税合計",
            // explanation: `消費税${AnalysisState.total_tax_each_tax_rate["8"]}円`,
            // value: Number(AnalysisState.total_amount_each_tax_rate["8"]).toLocaleString(),
            explanation: `消費税${AnalysisState.tax_08}円`,
            value: Number(AnalysisState.included_08).toLocaleString(),
            unit: "円",
        },
        {
            label: "仕入れ合計",
            explanation: "(税込み)",
            value: Number(AnalysisState.purchase_group_type_sum).toLocaleString(),
            unit: "円",
        },
        {
            label: "課税売上",
            explanation: "(総売上-仕入れ)",
            value: (Number(AnalysisState.total_amount) - purchase_sum).toLocaleString(),
            unit: "円",
        },
        {
            label: "人件費合計",
            value: personnel_fee.toLocaleString(),
            unit: "円",
        },
        {
            label: "消費税",
            explanation: "",
            // value: AnalysisState.total_tax.toLocaleString(),
            value: AnalysisState.tax_10 + AnalysisState.tax_08,
            unit: "円",
        },
        {
            label: "純利益",
            explanation: "(課税売上-消費税-人件費)",
            value: (total_amount - Number(AnalysisState.total_tax) - personnel_fee - purchase_sum).toLocaleString(),
            unit: "円",
        },
    ];

    // const HandleCheck = (e, staff_drink_data, index) => {
    //     console.log(staff_drink_data);
    //     console.log(e.target.checked);
    // };
    const tableHeadStyle = { borderBottom: "solid 1px gray", p: "0.4rem", background: "#a0c2fd", height: "3.2rem" };
    const tableBodyStyle = { borderBottom: "solid 1px gray", p: "0.4rem", height: "2.3rem" };
    return (
        <React.Fragment>
            <Container sx={{ pt: 2, textAlign: "center" }}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <TextField
                        sx={{ m: 1 }}
                        label="月"
                        type="month"
                        onChange={HandleChangeMonth}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={`${year}-${month}`}
                        // fullWidth
                    />
                    <FormControl sx={{ m: 1, width: "12rem" }}>
                        <InputLabel id="select-payment_methods-label">お支払い方法</InputLabel>
                        <Select
                            fullWidth
                            labelId="select-payment_methods-label"
                            label="お支払い方法"
                            value={payment_methods}
                            onChange={HandleChangePaymentMethods}
                        >
                            <MenuItem value={"all"}>すべて</MenuItem>
                            <MenuItem value={"cash"}>現金のみ</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    {data_list.map((data, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={4} sx={{ textAlign: "left", borderBottom: "solid 1px gray", p: 1 }}>
                                {data.label}
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: "right", borderBottom: "solid 1px gray", p: 1 }}>
                                {data?.explanation ? data.explanation + ":" : ":"}
                            </Grid>
                            <Grid item xs={3} sx={{ textAlign: "right", borderBottom: "solid 1px gray", p: 1 }}>
                                {data.value}
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "left", borderBottom: "solid 1px gray", p: 1 }}>
                                {data.unit}
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
                {/* <Grid container direction="column" justifyContent="space-between" alignItems="flex-start"> */}
                <Grid sx={{ marginTop: "2rem" }}>分類別仕入れ総額</Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{ border: "solid 1px gray", mt: "1rem" }}
                >
                    <Grid item xs={6} sx={tableHeadStyle}>
                        分類
                    </Grid>
                    <Grid item xs={6} sx={tableHeadStyle}>
                        金額
                    </Grid>
                    {AnalysisState.purchase_group_type.map((val, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={6} sx={{ borderBottom: "solid 1px gray", p: "0.4rem" }}>
                                {val?.category_type ? val?.category_type : "未分類"}
                            </Grid>
                            <Grid item xs={6} sx={{ borderBottom: "solid 1px gray", p: "0.4rem" }}>
                                {Number(val?.total_price).toLocaleString()}円
                            </Grid>
                        </React.Fragment>
                    ))}
                    <Grid item xs={6} sx={{ p: "0.4rem", fontWeight: "bold" }}>
                        合計
                    </Grid>
                    <Grid item xs={6} sx={{ p: "0.4rem", fontWeight: "bold" }}>
                        {Number(AnalysisState.purchase_group_type_sum).toLocaleString()}円
                    </Grid>
                </Grid>
                {open_purchase_group_category ? (
                    <>
                        <Grid container>
                            <Button onClick={() => setOpenPurchaseGroupCategory(false)}>閉じる</Button>{" "}
                        </Grid>
                        分類別仕入れ詳細
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            sx={{ border: "solid 1px gray" }}
                        >
                            {AnalysisState.purchase_group_category.map((val, index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={6} sx={{ borderBottom: "solid 1px gray", p: "0.4rem" }}>
                                        {val?.category_name ? val?.category_name : "未分類"}
                                    </Grid>
                                    <Grid item xs={6} sx={{ borderBottom: "solid 1px gray", p: "0.4rem" }}>
                                        {Number(val?.total_price).toLocaleString()}円
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                    </>
                ) : (
                    <Grid container>
                        <Button onClick={() => setOpenPurchaseGroupCategory(true)}>詳細</Button>{" "}
                    </Grid>
                )}
                {/* </Grid> */}
                人件費
                <br />
                (締め日{config.attendance_deadline == 1 ? "月末" : config.attendance_deadline - 1 + "日"})
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{ border: "solid 1px gray" }}
                >
                    <Grid item xs={3} sx={tableHeadStyle}>
                        名前
                    </Grid>

                    <Grid item xs={3} sx={tableHeadStyle}>
                        時給
                    </Grid>
                    <Grid item xs={3} sx={tableHeadStyle}>
                        {"合計勤務時間"}
                    </Grid>
                    <Grid item xs={3} sx={tableHeadStyle}>
                        合計給与額
                    </Grid>
                    {AnalysisState.attendance_hourly_sum.map((val, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={3} sx={{ borderBottom: "solid 1px gray", p: "0.4rem" }}>
                                {val?.staff_name ? val?.staff_name : "未分類"}
                            </Grid>
                            <Grid item xs={3} sx={{ borderBottom: "solid 1px gray", p: "0.4rem" }}>
                                {Number(val?.hourly).toLocaleString()}円
                            </Grid>
                            <Grid item xs={3} sx={{ borderBottom: "solid 1px gray", p: "0.4rem" }}>
                                {Math.floor(Number(val?.total_working_hours) / 60)}時間{" "}
                                {Number(val?.total_working_hours) % 60}分
                            </Grid>
                            <Grid item xs={3} sx={{ borderBottom: "solid 1px gray", p: "0.4rem" }}>
                                {Math.round(Number(val?.total_salary))
                                    // (Number(val?.total_working_hours) * Number(val?.hourly ? val?.hourly : 0)) / 60

                                    .toLocaleString()}
                                円
                            </Grid>
                        </React.Fragment>
                    ))}
                    <Grid item xs={3} sx={{ p: "0.4rem", fontWeight: "bold" }}>
                        合計
                    </Grid>
                    <Grid item xs={6} sx={{ p: "0.4rem" }}></Grid>

                    <Grid item xs={3} sx={{ p: "0.4rem", fontWeight: "bold" }}>
                        {Number(AnalysisState?.attendance_salary_total).toLocaleString()}円
                        {/* {Number(AnalysisState?.attendance_hourly_sum_total).toLocaleString()}円 */}
                    </Grid>
                </Grid>
                {config.staff_drink ? (
                    <>
                        <br /> <br />
                        スタッフドリンク集計
                        <br />
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ border: "solid 1px gray" }}
                        >
                            <Grid item xs={1} sx={tableHeadStyle}></Grid>
                            <Grid item xs={3} sx={tableHeadStyle}>
                                スタッフ名
                            </Grid>
                            <Grid item xs={2} sx={tableHeadStyle}>
                                合計金額
                            </Grid>
                            <Grid item xs={1} sx={tableHeadStyle}>
                                数
                            </Grid>
                            <Grid item xs={5} sx={tableHeadStyle}>
                                バック金額
                                <br />
                                (合計金額の{config.staff_drink_back}%)
                            </Grid>
                            {AnalysisState.staff_drink_data.map((staff_drink_data, index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={1} sx={tableBodyStyle}>
                                        {/* <Checkbox
                                            sx={{ p: 0 }}
                                            onChange={(e) => HandleCheck(e, staff_drink_data, index)}
                                        /> */}
                                    </Grid>
                                    <Grid item xs={3} sx={tableBodyStyle}>
                                        {staff_drink_data.staff_data?.staff_name}
                                    </Grid>
                                    <Grid item xs={2} sx={tableBodyStyle}>
                                        {staff_drink_data?.total_menu_price}
                                    </Grid>
                                    <Grid item xs={1} sx={tableBodyStyle}>
                                        {staff_drink_data?.count_staff_drink}
                                    </Grid>

                                    <Grid item xs={5} sx={tableBodyStyle}>
                                        {Math.floor(
                                            (staff_drink_data.total_menu_price * config.staff_drink_back) / 100
                                        )}
                                        円
                                    </Grid>
                                </React.Fragment>
                            ))}
                            {/* <Grid item xs={1} sx={tableBodyStyle}></Grid> */}
                            <Grid item xs={4} sx={tableBodyStyle}>
                                合計
                            </Grid>
                            <Grid item xs={2} sx={tableBodyStyle}>
                                {AnalysisState.staff_drink_sum.total_menu_price}
                            </Grid>
                            <Grid item xs={1} sx={tableBodyStyle}>
                                {AnalysisState.staff_drink_sum.count_staff_drink}
                            </Grid>
                            <Grid item xs={5} sx={tableBodyStyle}>
                                {Math.floor(
                                    (AnalysisState.staff_drink_sum.total_menu_price * config.staff_drink_back) / 100
                                )}
                                円
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    ""
                )}
                <Grid sx={{ marginTop: "2rem" }}>日別売上</Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <LineChart
                        width={800}
                        height={250}
                        data={visit_aggregate}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" />

                        <YAxis yAxisId="left" label={{ value: "人数", angle: 90, position: "insideLeft" }} />
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            label={{ value: "売上", angle: 90, position: "right" }}
                        />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" yAxisId="left" dataKey="人数" stroke="#d88484" />
                        <Line type="monotone" yAxisId="right" dataKey="売上" stroke="#8884d8" />
                    </LineChart>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    {sub_data_list.map((data, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={4} sx={{ textAlign: "left", borderBottom: "solid 1px gray", p: 1 }}>
                                {data.label}
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: "right", borderBottom: "solid 1px gray", p: 1 }}>
                                {data?.explanation ? data.explanation + ":" : ":"}
                            </Grid>
                            <Grid item xs={3} sx={{ textAlign: "right", borderBottom: "solid 1px gray", p: 1 }}>
                                {data.value}
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "left", borderBottom: "solid 1px gray", p: 1 }}>
                                {data.unit}
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item>
                        <Grid sx={{ marginTop: "2rem" }}>商品別売上</Grid>
                        <PieChart width={800} height={600}>
                            <Pie
                                data={AnalysisState.accounting_group_type}
                                dataKey="total_menu_price"
                                outerRadius={270}
                                label={customizedLabel}
                                nameKey="type_label"
                                labelLine={false}
                            >
                                {AnalysisState.accounting_group_type.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                        </PieChart>
                    </Grid>
                    {/* <Grid item>
                        商品別売上
                        <PieChart width={380} height={380}>
                            <Pie
                                data={AnalysisState.accounting_group_type}
                                dataKey="total_menu_price"
                                outerRadius={150}
                                label={customizedLabel}
                                nameKey="type_label"
                                labelLine={false}
                            >
                                {AnalysisState.accounting_group_type.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                        </PieChart>
                    </Grid> */}
                </Grid>
                月別売上
                <BarChart width={800} height={250} data={AnalysisState.total_amount_month}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="m" />
                    <YAxis />
                    <Tooltip
                        formatter={(value, name, props) => [value + "円", "売上合計"]}
                        // labelFormatter={(value, props) => console.log(props)}
                    />
                    {/* <Legend /> */}
                    <Bar dataKey="amount" fill="#8884d8" />
                </BarChart>
            </Container>
        </React.Fragment>
    );
};

export default Analysis;
