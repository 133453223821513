import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    user_info: {
        user_id: "",
        email: "",
        permission: "general",
    },
    login_status: false, //false,user
    user_token: "",
    config: {
        system_name: "system",
        theme_color: "purple",
        time_price: true,
        bottle_keep: true,
        staff_drink: true,
        staff_drink_back: 20,
        accounting_detail_background: "/images/Background/back.png",
        accounting_detail_logo: "/images/Logo/SLYlogo.png",
        cache_res_commission: 5,
        receipt_confirmation: false,
        receipt_address: "",
        receipt_telephone: "",
        receipt_invoice: "", //インボイス登録番号
        receipt_proviso: "", //但し書き
        default_type: "", //デフォルトの商品タイプ
        attendance_deadline: 1, //勤怠確認締め日
        menu_view: false, //メニュー画面の表示
        preparation: false, //料理の準備中機能
        bottle_keep_price: 3500, //ボトルキープ時間価格
        first_1_hour: 2500, //最初1時間
        first_2_hour: 4500, //最初2時間
        half_hour_price: 1000, //以降30分ごとの価格
        charge: 500, //チャージ(最初の固定時間料金との差額)
        cash_less_fee_label: "サービス料",
    },
};
export const TokenLogin = (arg) => {
    return AsyncFunction({
        type_name: "Information/トークンログイン",
        method: "post",
        url: "/api/TokenLogin",
        params: arg,
    });
};

export const UserLogin = (arg) => {
    return AsyncFunction({
        type_name: "Information/ユーザーログイン",
        method: "post",
        url: "/api/UserLogin",
        params: arg,
    });
};

export const UserSignup = (arg) => {
    return AsyncFunction({
        type_name: "Information/ユーザー新規登録",
        method: "post",
        url: "/api/UserSignup",
        params: arg,
    });
};

export const UserInfoPost = (arg) => {
    return AsyncFunction({
        type_name: "Information/ユーザー詳細登録",
        method: "post",
        url: "/api/UserInfoPost",
        params: arg,
    });
};

export const PutPassword = (arg) => {
    return AsyncFunction({
        type_name: "Information/ユーザーパスワード変更",
        method: "put",
        url: "/api/ChangePassword",
        params: arg,
    });
};
export const PutEmail = (arg) => {
    return AsyncFunction({
        type_name: "Information/ユーザーメールアドレスの変更",
        method: "put",
        url: "/api/ChangeEmail",
        params: arg,
    });
};

export const PostUserOneTime = (arg) => {
    return AsyncFunction({
        type_name: "OneTime/ユーザーワンタイムパスワードの申請",
        method: "post",
        url: "/api/PostUserOneTime",
        params: arg,
    });
};

export const PutConfig = (arg) => {
    return AsyncFunction({
        type_name: "Information/設定の変更",
        method: "put",
        url: "/api/PutConfig",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "Information",
    initialState,
    reducers: {
        Information_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
        UserLogout: (state, action) => {
            state = { ...initialState, login_status: "logout" };
            localStorage.removeItem("user_token");
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.match("Information/") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    const payload = action.payload;
                    if (payload.result != "OK") return;

                    if (payload?.user_info) {
                        state.user_info = payload?.user_info;
                    }

                    if (payload?.user_token) {
                        state.user_token = payload?.user_token;
                        state.login_status = "user";
                        localStorage.setItem("user_token", payload?.user_token);
                    }
                    if (payload?.general_config) {
                        state.config = { ...state.config, ...payload?.general_config };
                    }
                }
            )
            .addMatcher(
                (action) => action.type.match("Information/トークンログイン"),
                (state, action) => {
                    // トークンログインに失敗した場合、トップページにリダイレクト
                    switch (true) {
                        case action.type.endsWith("/fulfilled") && action.payload.result == "NG":
                            state.login_status = "failure";
                            break;
                        case action.type.endsWith("/rejected"):
                            state.login_status = "failure";
                            break;
                        default:
                            break;
                    }
                }
            );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { Information_set, UserLogout } = slice.actions;
