import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    visit_aggregate: [],
    total_people: 0,
    total_amount: 0,
    accounting_group_type: [],
    total_menu_price: 0,
    purchase_group_category: [],
    purchase_group_category_sum: 0,
    purchase_group_type: [],
    purchase_group_type_sum: 0,
    menu_type_list: [],
    attendance_hourly_sum: [],
    attendance_salary_sum: 0,
    attendance_hourly_sum_total: 0,
    total_round_amount: 0,
    total_amount_no_cash: 0,
    attendance_salary_total: 0,
    total_amount_month: [
        { m: "01", amount: 0 },
        { m: "02", amount: 0 },
        { m: "03", amount: 0 },
        { m: "04", amount: 0 },
        { m: "05", amount: 0 },
        { m: "06", amount: 0 },
        { m: "07", amount: 0 },
        { m: "08", amount: 0 },
        { m: "09", amount: 0 },
        { m: "10", amount: 0 },
        { m: "11", amount: 0 },
        { m: "12", amount: 0 },
    ],
    staff_drink_data: [],
    staff_drink_sum: { total_menu_price: 0, count_staff_drink: 0 },

    total_amount_each_tax_rate: { 8: 0, 10: 0 },
    total_tax_each_tax_rate: { 8: 0, 10: 0 },
    total_tax: 0,

    included_08: 0,
    included_10: 0,
    tax_08: 0,
    tax_10: 0,
};
export const GetVisitAggregate = (arg) => {
    return AsyncFunction({
        type_name: "Analysis/取得",
        method: "get",
        url: "/api/GetVisitAggregate",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "Analysis",
    initialState,
    reducers: {
        Analysis_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("Analysis") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                const params = action.meta.arg.params;

                if (payload?.visit_aggregate) {
                    const visit_aggregate = payload?.visit_aggregate.map((val, index) => {
                        return {
                            ...val,
                            day:
                                val.day +
                                "日(" +
                                ["日", "月", "火", "水", "木", "金", "土"][
                                    new Date(params.year, params.month - 1, val.day).getDay()
                                ] +
                                ")",
                            売上: val.total_amt,
                            人数: val.total_people,
                        };
                    });
                    state.visit_aggregate = visit_aggregate;
                }
                if (payload?.total_people) {
                    state.total_people = payload.total_people;
                }
                if (payload?.total_amount) {
                    state.total_amount = payload.total_amount;
                }
                if (payload?.accounting_group_type) {
                    const accounting_group_type = payload?.accounting_group_type.map((val, index) => {
                        let type_label = "";
                        switch (val.type) {
                            case "0":
                                type_label = "その他";
                                break;
                            case "2":
                                type_label = "シャンパン";
                                break;
                            case "3":
                                type_label = "ボトル";
                                break;
                            case "9":
                                type_label = "スタッフドリンク";
                                break;
                            default:
                                type_label = "不明";
                                break;
                        }

                        if (val.type && payload.menu_type_list[val.type]) {
                            type_label = payload.menu_type_list[val.type].menu_type_name;
                        }
                        return {
                            ...val,
                            type_label: type_label,
                        };
                    });

                    state.accounting_group_type = accounting_group_type;
                }

                if (payload?.total_menu_price) {
                    state.total_menu_price = payload.total_menu_price;
                }
                if (payload?.purchase_group_category) {
                    state.purchase_group_category = payload.purchase_group_category;

                    const sum = Object.keys(payload.purchase_group_category).reduce(
                        (sum, key) => sum + parseInt(payload.purchase_group_category[key].total_price || 0),
                        0
                    );
                    state.purchase_group_category_sum = sum;
                }
                if (payload?.purchase_group_type) {
                    state.purchase_group_type = payload.purchase_group_type;
                    const sum = Object.keys(payload.purchase_group_type).reduce(
                        (sum, key) => sum + parseInt(payload.purchase_group_type[key].total_price || 0),
                        0
                    );
                    state.purchase_group_type_sum = sum;
                }
                if (payload?.menu_type_list) {
                    state.menu_type_list = payload.menu_type_list;
                }
                if (payload?.attendance_hourly_sum) {
                    state.attendance_hourly_sum = payload.attendance_hourly_sum;

                    const total_price_list = payload.attendance_hourly_sum.map((value, index) => {
                        return Math.round(value.hourly * (value.total_working_hours / 60));
                    });

                    let total = total_price_list.reduce((sum, element) => sum + element, 0);
                    state.attendance_hourly_sum_total = total;

                    const total_salary_list = payload.attendance_hourly_sum.map((value, index) => {
                        return Math.round(value.total_salary);
                    });
                    let attendance_salary_total = total_salary_list.reduce((sum, element) => sum + element, 0);
                    state.attendance_salary_total = attendance_salary_total;
                }
                if (payload?.total_round_amount) {
                    state.total_round_amount = payload.total_round_amount;
                }
                if (payload?.total_amount_no_cash) {
                    state.total_amount_no_cash = payload.total_amount_no_cash;
                }
                if (payload?.total_amount_month) {
                    const total_amount_month = initialState.total_amount_month.map((val, index) => {
                        const this_m = payload.total_amount_month[val.m];
                        if (this_m) {
                            return { m: val.m + "月", amount: this_m };
                        } else {
                            return { m: val.m + "月", amount: val.amount };
                        }
                    });

                    state.total_amount_month = total_amount_month;
                }

                if (payload?.staff_drink_data) {
                    state.staff_drink_data = payload.staff_drink_data;
                }

                if (payload?.staff_drink_sum) {
                    state.staff_drink_sum = payload.staff_drink_sum[0];
                }
                if (payload?.attendance_salary_sum) {
                    state.attendance_salary_sum = payload.attendance_salary_sum;
                }
                //税率ごとの注文品合計額
                if (payload?.total_amount_each_tax_rate) {
                    state.total_amount_each_tax_rate = payload.total_amount_each_tax_rate;
                }
                //税率ごとの消費税額
                if (payload?.total_tax_each_tax_rate) {
                    state.total_tax_each_tax_rate = payload.total_tax_each_tax_rate;
                }
                if (payload?.total_tax || payload?.total_tax === 0) {
                    state.total_tax = payload.total_tax;
                }
                if (payload?.included_08 || payload?.included_08 === 0) {
                    state.included_08 = payload.included_08;
                }
                if (payload?.included_10 || payload?.included_10 === 0) {
                    state.included_10 = payload.included_10;
                }
                if (payload?.tax_08 || payload?.tax_08 === 0) {
                    state.tax_08 = payload.tax_08;
                }
                if (payload?.tax_10 || payload?.tax_10 === 0) {
                    state.tax_10 = payload.tax_10;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { Analysis_set } = slice.actions;
