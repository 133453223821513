import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Container from "@mui/material/Container";

import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "~/components/inputs/InputField";

import { UserLogin } from "~slices/Information";
import { Link } from "react-router-dom";

import { GetMenuTypeList, MenuType_set, PostMenuType, DeleteMenuType } from "~slices/MenuType";

import { styled } from "@mui/material/styles";

import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";

import { now } from "lodash";

const HeadGrid = styled(Grid)({
    padding: 2,
    border: "1px solid grey",
    // background: "#9bc6fa",
    // fontSize: "0.8rem",
    textAlign: "center",
    borderRadius: 2,
    fontWeight: "bold",
    fontSize: "1rem",
});
const BodyGrid = styled(Grid)({
    padding: 2,
    border: "1px solid grey",
    // fontSize: "0.9rem",
    overflowWrap: "break-word",
    borderRadius: 2,
    fontSize: "1rem",
});

const MenuTypeSetting = () => {
    const dispatch = useDispatch();

    const [type, setType] = React.useState(0);
    const [tax_rate, setTaxRate] = React.useState(10);
    const [menu_type_name, setMenuTypeName] = React.useState("");
    React.useEffect(() => {
        dispatch(GetMenuTypeList()).then((data) => {
            console.log(data);
        });
    }, []);
    const CreateMenuType = () => {
        const params = {
            type: type,
            create_data: {
                // type: type,
                tax_rate: tax_rate,
                menu_type_name: menu_type_name,
            },
        };
        dispatch(PostMenuType(params)).then((data) => {
            console.log("menu_type");
        });
    };

    const HandleChangeType = (e) => {
        setType(e.target.value);

        const params = {
            type: e.target.value,
        };
        dispatch(GetMenuTypeList(params)).then((data) => {});
    };
    return (
        <React.Fragment>
            <Container sx={{ pt: 2 }}>
                <Typography variant="h3" align="center" sx={{ pb: 2 }}>
                    商品種別管理
                </Typography>

                <Grid sx={{ border: "1px solid grey", p: 2 }} container direction="row" justifyContent="space-around">
                    <TextField
                        sx={{ m: 1, width: "20rem" }}
                        label="名前"
                        value={menu_type_name}
                        onChange={(e) => setMenuTypeName(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <FormControl>
                        <InputLabel id="select-tax_rate-label">税率</InputLabel>
                        <Select
                            sx={{ m: 1, width: "12rem" }}
                            fullWidth
                            labelId="select-tax_rate-label"
                            label="税率"
                            value={tax_rate}
                            onChange={(e) => setTaxRate(e.target.value)}
                        >
                            {/* {[...Array(21)].map((_, i) => (
                                <MenuItem key={i} value={i}>
                                    {i}%
                                </MenuItem>
                            ))} */}
                            <MenuItem key={8} value={8}>
                                8%
                            </MenuItem>
                            <MenuItem key={10} value={10}>
                                10%
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {/* <TextField
                        sx={{ m: 1, width: "8rem" }}
                        label="タイプ"
                        type="number"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    /> */}
                    <Button variant="contained" sx={{ height: "4rem", width: "6rem" }} onClick={CreateMenuType}>
                        登録
                    </Button>
                </Grid>
                <MenuTypeTable type={type} />
            </Container>
        </React.Fragment>
    );
};

const MenuTypeTable = (props) => {
    const MenuTypeState = useSelector((state) => state.MenuType);
    const dispatch = useDispatch();
    const menu_type_list = MenuTypeState.menu_type_list;
    const DellMenuType = (menu_type_data) => {
        dispatch(
            DeleteMenuType({
                menu_type_id: menu_type_data.menu_type_id,
            })
        );
    };
    // const menu_type_list = [{ menu_type_name: "aaaa", manu_price: 1000 }];

    return (
        <>
            <Grid container sx={{ mt: 1 }}>
                <HeadGrid item xs={7}>
                    種別名
                </HeadGrid>
                <HeadGrid item xs={3}>
                    税率
                </HeadGrid>
                {/* <HeadGrid item xs={2}>
                    タイプNo
                </HeadGrid> */}
                <HeadGrid item xs={2}></HeadGrid>

                {menu_type_list.map((menu_type, index) => (
                    <React.Fragment key={index}>
                        <BodyGrid item xs={7} sx={{ p: "4px" }}>
                            {menu_type.menu_type_name}
                        </BodyGrid>
                        <BodyGrid item xs={3} sx={{ p: "4px", textAlign: "right" }}>
                            {menu_type.tax_rate}%
                        </BodyGrid>
                        {/* <BodyGrid item xs={2} sx={{ textAlign: "right", pt: 1, pr: 1 }}>
                            {menu_type.type}
                        </BodyGrid> */}
                        <BodyGrid item xs={2} sx={{ textAlign: "right" }}>
                            <Button fullWidth color="error" onClick={() => DellMenuType(menu_type)}>
                                削除
                            </Button>
                        </BodyGrid>
                    </React.Fragment>
                ))}
                {/* 合計{price}円
                <Grid>
                    税込み
                    <div style={{ fontSize: "2rem", display: "inline" }}>{Math.floor(price * 1.1)}</div>円
                </Grid> */}
            </Grid>
        </>
    );
};

export default MenuTypeSetting;
