import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    visit_list: [],
    visit_data: {},
    accounting_list: [],
    accounting_list_group: [],
    time_price: 0,
    endTime: "",
    stay_time: 0,
    OpenAccountingPopup: false,
    OpenAccountingDetailPopup: false,
    sort: "visit_time,asc",
    total_amount_rate: { 10: 0 }, //税率ごとの合計金額
    tax_amount_rate: { 10: 0 }, //税率ごとの税額
    total_tax: 0, //合計税額
    included_by_rate: { 10: 0 },
    tax_10: 0, //10%税
    tax_08: 0, //8%税
    tax_excluded: 0, //税抜き金額
};

export const GetVisitList = (arg) => {
    return AsyncFunction({
        type_name: "Visit/来店一覧取得",
        method: "get",
        url: "/api/GetVisitList",
        params: arg,
    });
};
export const GetVisit = (arg) => {
    return AsyncFunction({
        type_name: "Visit/来店データの取得",
        method: "get",
        url: "/api/GetVisit",
        params: arg,
    });
};

export const PostVisit = (arg) => {
    return AsyncFunction({
        type_name: "Visit/新規来店",
        method: "post",
        url: "/api/PostVisit",
        params: arg,
    });
};
export const GetCheckData = (arg) => {
    return AsyncFunction({
        type_name: "Accounting/個別の会計情報取得",
        method: "get",
        url: "/api/GetCheckData",
        params: arg,
    });
};

export const PutVisit = (arg) => {
    return AsyncFunction({
        type_name: "Visit/来店情報編集",
        method: "put",
        url: "/api/PutVisit",
        params: arg,
    });
};
export const DeleteVisit = (arg) => {
    return AsyncFunction({
        type_name: "Visit/来店情報削除",
        method: "delete",
        url: "/api/DeleteVisit",
        params: arg,
    });
};

export const PostAccounting = (arg) => {
    return AsyncFunction({
        type_name: "Accounting/商品の追加",
        method: "post",
        url: "/api/PostAccounting",
        params: arg,
    });
};

export const PutAccounting = (arg) => {
    return AsyncFunction({
        type_name: "PutAccounting/商品の編集",
        method: "put",
        url: "/api/PutAccounting",
        params: arg,
    });
};
export const DeleteAccounting = (arg) => {
    return AsyncFunction({
        type_name: "Accounting/商品の削除",
        method: "delete",
        url: "/api/DeleteAccounting",
        params: arg,
    });
};

export const GetBase64PdfReceipt = (arg) => {
    return AsyncFunction({
        type_name: "Receipt/領収書Base64取得",
        method: "get",
        url: "/api/GetBase64PdfReceipt",
        params: arg,
    });
};

// export const DeleteCheck = (arg) => {
//     return AsyncFunction({
//         type_name: "Check/チャット削除",
//         method: "delete",
//         url: "/api/DeleteCheck",
//         params: arg,
//     });
// };

// Sliceを生成する
const slice = createSlice({
    name: "Check",
    initialState,
    reducers: {
        Check_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.match("Visit") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    const payload = action.payload;
                    if (payload?.result != "OK") return;
                    if (payload?.visit_list) {
                        state.visit_list = payload?.visit_list;
                    }
                    if (payload?.visit_data) {
                        state.visit_data = payload?.visit_data;
                    }

                    //税率ごとの合計金額
                    if (payload?.total_amount_rate) {
                        state.total_amount_rate = payload?.total_amount_rate;
                    }
                    //税率ごとの税額
                    if (payload?.tax_amount_rate) {
                        state.tax_amount_rate = payload?.tax_amount_rate;
                    }
                    if (payload?.total_tax) {
                        state.total_tax = payload?.total_tax;
                    }
                    //税率ごとの税込金額
                    if (payload?.included_by_rate) {
                        state.included_by_rate = payload?.included_by_rate;
                    }
                    if (payload?.tax_10) {
                        state.tax_10 = payload?.tax_10;
                    }

                    if (payload?.tax_08) {
                        state.tax_08 = payload?.tax_08;
                    }

                    if (payload?.tax_excluded) {
                        state.tax_excluded = payload?.tax_excluded;
                    }
                }
            )
            .addMatcher(
                (action) => action.type.match("Accounting") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    const payload = action.payload;
                    if (payload?.result != "OK") return;
                    if (payload?.accounting_list) {
                        state.accounting_list = payload?.accounting_list;

                        const group = payload?.accounting_list.reduce((result, current) => {
                            const element = result.find(
                                (p) => p.menu_name === current.menu_name && p.menu_price === current.menu_price
                            );

                            if (element) {
                                element.count++; // count
                                element.total_price = Number(element.total_price) + Number(current.menu_price); // sum
                            } else {
                                result.push({
                                    type: current.type,
                                    visit_id: current.visit_id,
                                    accounting_id: current.accounting_id,
                                    menu_name: current.menu_name,
                                    count: 1,
                                    menu_price: current.menu_price,
                                    total_price: current.menu_price,
                                    staff_id: current.staff_id,
                                    menu_id: current.menu_id,
                                });
                            }
                            return result;
                        }, []);

                        state.accounting_list_group = group;
                    }
                }
            );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { Check_set } = slice.actions;
